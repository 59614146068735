export const TREE_CANOPY_LAYER_STYLE = {
  color: "var(--colors-white)",
  fill: true,
  fillColor: "rgb(56, 6, 73)",
  opacity: 1,
  weight: 1,
  fillOpacity: 1,
};

export const TREE_CANOPY_LAYER_STYLE_SELECTED = {
  color: "var(--colors-white)",
  opacity: 1,
  weight: 3,
  fillOpacity: 1,
};
export const TREE_CANOPY_LEGEND: Record<string, { min: number; max: number; color: string; name: string }> = {
  LOW1: { min: 0, max: 35, color: "#04287B", name: "35 ft" },
  LOW2: { min: 35, max: 45, color: "#1749A2", name: "45 ft" },
  MEDIUM: { min: 45, max: 55, color: "#7614B7", name: "55 ft" },
  HIGH1: { min: 55, max: 70, color: "#C512CF", name: "70 ft" },
  HIGH2: { min: 70, max: 100, color: "#F42097", name: "75 ft" },
};

export const TREE_SPECIES_NAMES = {
  Dying: "Dying",
  Deciduous: "Other deciduous",
  Pear: "Bradford pear",
  Cedar: "Cedar",
  Maple: "Maple",
  Pine: "Pine",
};

export const CANOPY_CENTER_STYLE = {
  radius: 0.5,
  color: "var(--colors-white)",
  fillColor: "var(--colors-white)",
  fillOpacity: 1,
  interactive: false,
};

export const TREE_CANOPY_LAYER_KEY = "TREE_CANOPY";

export const TREE_CANOPY_PANE_NAME = "tree-layer-pane";

export const TREE_CANOPY_POPUP_CLASS_NAME = "tree-canopy-popup";

export const TILE_SERVER_COOKIE_KEY = "TILE_SERVER_COOKIES";

export const MIN_ZOOM_LEVEL_FOR_TREE_CANOPY_LAYER = 17;

export const MIN_CANOPY_RADIUS = 0.24;

export const MAX_CANOPY_RADIUS = 0.46;

export const CANOPY_RADIUS_ZOOM = 19.36;

export const TREE_CANOPY_ZINDEX = 401;

//parcels

export const MIN_ZOOM_LEVEL_FOR_PARCELS_LAYER = 14;

export const MIN_ZOOM_LEVEL_FOR_DETAILED_PARCELS = 17;

export const MIN_ZOOM_LEVEL_FOR_PARCEL_MARKERS = 15;

export const PARCELS_Z_INDEX = 390;

export const PARCELS_MARKERS_Z_INDEX = 410;

export const PARCELS_STYLE = {
  color: "var(--colors-white)",
  fill: false,
  opacity: 0.47,
  weight: 3,
};

export const PARCELS_HOVER_STYLE = {
  color: "var(--colors-cyan)",
  fill: false,
  opacity: 1,
  weight: 3,
};

export const PARCELS_SELECTED_STYLE = {
  color: "var(--colors-cyan)",
  fill: true,
  fillColor: "rgba(46, 187, 196, 0.33)",
  opacity: 1,
  weight: 2,
};

export const PARCELS_NOTIFIED_STYLE = {
  color: "var(--colors-notified)",
  fill: true,
  fillColor: "var(--colors-notified)",
  opacity: 1,
  fillOpacity: 0.1,
  weight: 3,
};

export const PARCELS_APPROVED_STYLE = {
  color: "var(--colors-approved)",
  fill: true,
  fillColor: "var(--colors-approved)",
  opacity: 1,
  fillOpacity: 0.1,
  weight: 3,
};

export const PARCELS_REFUSED_STYLE = {
  color: "var(--colors-refused)",
  fill: true,
  fillColor: "var(--colors-refused)",
  opacity: 1,
  fillOpacity: 0.1,
  weight: 3,
};

export const PARCELS_CONCERNED_STYLE = {
  color: "var(--colors-concerned)",
  fill: true,
  fillColor: "var(--colors-concerned)",
  fillOpacity: 0.1,
  opacity: 1,
  weight: 3,
};

export const PARCELS_NOT_ASSIGNED_STYLE = {
  color: "var(--colors-not-assigned-outline)",
  fill: true,
  fillColor: "var(--colors-not-assigned)",
  opacity: 1,
  fillOpacity: 0.2,
  weight: 3,
};

export const PARCELS_SELECTED_LAYER_ON_STATUS = {
  color: "var(--colors-white)",
  fill: true,
  fillOpacity: 0.44,
  fillColor: "rgba(255, 255, 255)",
  opacity: 1,
  weight: 3,
};

export const PARCEL_STATUSES_LEGEND = {
  REFUSED: { bgColor: "var(--colors-refused-legend)", name: "Refused", markerColor: "var(--colors-refused-marker)" },
  APPROVED: { bgColor: "var(--colors-approved)", name: "Approved", markerColor: "var(--colors-approved-marker)" },
  CONCERNED: {
    bgColor: "var(--colors-concerned-legend)",
    name: "Concerned",
    markerColor: "var(--colors-concerned-marker)",
  },
  NOTIFIED: {
    bgColor: "var(--colors-notified-legend)",
    name: "Notified",
    markerColor: "var(--colors-notified-marker)",
  },
  NOT_ASSIGNED: {
    bgColor: "var(--colors-not-assigned)",
    name: "Unassigned",
    markerColor: "var(--colors-not-assigned-marker)",
  },
};

//structures

export const STRUCTURES_Z_INDEX = 400;

export const MIN_ZOOM_LEVEL_FOR_STRUCTURES = 14;

export const MIN_ZOOM_LEVEL_FOR_DETAILED_STRUCTURES = 18;

export const MIN_ZOOM_LEVEL_FOR_DOTS_STRUCTURES = 16;

//feeders

export const FEEDER_STYLE = {
  fillColor: "#2EB5D2",
  fillOpacity: 1,
  weight: 3,
  opacity: 1,
  color: "#3CE3FF",
};

export const FEEDER_PRIORITY_VIOLATION = {
  color: "#ff0000",
};
export const FEEDER_PRIORITY_CRITICAL = {
  color: "#ff7831",
};
export const FEEDER_PRIORITY_HIGH = {
  color: "#E5B710",
};
export const FEEDER_PRIORITY_MEDIUM = {
  color: "#25B9B9",
};
export const FEEDER_PRIORITY_LOW = {
  color: "#45B925",
};

export const FEEDER_STYLE_SELECTED = {
  fillColor: "#FDCA14",
  fillOpacity: 1,
  weight: 3,
  color: "#FFFFFF",
};

export const FEEDER_STYLE_HOVER = {
  color: "#FDCA14",
  weight: 3,
};

//spans => feeder-segments

export const MIN_ZOOM_LEVEL_FOR_SPANS_LAYER = 14.08;

export const SPAN_STYLE = {
  fillColor: "#2EB5D2",
  fillOpacity: 1,
  weight: 5,
  opacity: 1,
  color: "#3CE3FF",
};

export const SPAN_STYLE_WORK_TYPE = {
  fillColor: "#2EB5D2",
  fillOpacity: 1,
  weight: 13,
  opacity: 1,
  color: "#2EB5D2",
};

export const SPAN_STYLE_HOVER_SELECTED = {
  fillColor: "var(--colors-white)",
  fillOpacity: 1,
  weight: 5,
  opacity: 1,
  color: "var(--colors-white)",
};

//encroachments ==> threats

export const ENCROACHMENT_STYLE = {
  fillOpacity: 0.5,
  opacity: 0.5,
  weight: 2,
  fill: true,
  color: "rgba(255,255,255,0.5)",
};

export const ENCROACHMENT_STYLE_SELECTED = {
  fillOpacity: 0.8,
  opacity: 0.8,
  weight: 2,
  fill: true,
};
//clearance => spans

export const CLEARANCE_STYLE = {
  fillColor: "transparent",
  opacity: 0.44,
  color: "white",
  weight: 1.5,
};

export const CLEARANCE_STYLE_ACTIVE_INVISIBLE = {
  fillColor: "transparent",
  opacity: 0,
  color: "white",
  weight: 1.5,
};

export const CLEARANCE_STYLE_SELECTED = {
  fillColor: "rgb(253, 202, 20)",
  opacity: 1,
  color: "rgb(253, 202, 20)",
  weight: 4,
};

export const CLEARANCE_STYLE_OVERRIDEN = {
  opacity: 1,
};

//counties

export const COUNTY_Z_INDEX = 399;

export const MIN_ZOOM_LEVEL_FOR_COUNTIES_LAYER = 13;

export const COUNTY_STYLE = {
  fillColor: "transparent",
  fill: true,
  fillOpacity: 1,
  weight: 1,
  color: "rgba(255, 255, 255, 0.8)",
};

//additional layers

export const MIN_ZOOM_LEVEL_FOR_ADDITIONAL_LAYER_MARKERS = 14;

export const LANDS_STYLE = {
  fillColor: "rgb(0, 174, 122)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#00AE7A",
};

export const ROADS_STYLE = {
  fillColor: "rgb(255, 0, 0)",
  fillOpacity: 0.2,
  weight: 1,
  opacity: 1,
  color: "#EC8F96",
};

export const SENSITIVE_SPECIES_STYLE = {
  fillColor: "rgb(253, 225, 76)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#FF5F1F",
};

export const WILDFIRE_CLUSTERS_STYLE = {
  fillColor: "rgb(255, 95, 31)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#FDCA14",
};

export const BUILDINGS_STYLE = {
  fillColor: "rgb(172,153,214)",
  fillOpacity: 1,
  weight: 1,
  opacity: 1,
  color: "white",
};

export const DEMOGRAPHIC_STYLE = {
  fillColor: "rgb(255, 0, 0)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#EC8F96",
};

export const NONE_CATEGORY_STYLE = {
  fillColor: "rgb(253, 225, 76)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#FDCA14",
};

export const HYDROLOGY_FLOODPLAIN_STYLE = {
  fillColor: "rgba(43, 127, 255, 0.44)",
  fillOpacity: 1,
  weight: 1,
  opacity: 1,
  color: "transparent",
};

export const HYDROLOGY_FLOODWAY_STYLE = {
  fillColor: "rgba(2, 28, 67, 0.51)",
  fillOpacity: 1,
  weight: 1,
  opacity: 1,
  color: "rgba(255, 255, 255, 0.5)",
};

export const HYDROLOGY_DEFAULT_STYLE = {
  fillColor: "rgba(0, 27, 98, 0.7)",
  fillOpacity: 1,
  weight: 1,
  opacity: 1,
  color: "rgba(255, 255, 255, 0.5)",
};

export const HYDROLOGY_LAYER_NAMES = {
  Floodplain: "Floodplain",
  Floodway: "Floodway",
};

export const HYDROLOGY_LAYER_COLORS = {
  Floodplain: "rgba(43, 127, 255, 0.44)",
  Floodway: "rgba(2, 28, 67, 0.51)",
};

//urban areas

export const URBAN_STYLE = {
  fillColor: "#BB940B",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#FFC700",
};

// danger trees

export const DANGER_TREE_STYLE = {
  color: "var(--colors-white)",
  fill: true,
  opacity: 1,
  weight: 1,
  fillOpacity: 0.8,
};

export const DANGER_TREE_SELECTED_STYLED = {
  color: "blue",
  weight: 2,
};
